import React from "react";

import styles from "./Socials.module.scss"

const Socials = ({icon, link, num}) => {
    return (
        <>
        <div className={styles.circle} onClick={
            num === "phone" ? 
            ()=>{
                window.location.href = link                
            } : ()=>window.open(link)}>
                    <div className={styles.icon}>
                        {icon}
                    </div>
                </div>
        </>
    )
}

export default Socials;