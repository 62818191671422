import React from "react";

import styles from "./AboutMenu.module.scss";
import Button from "../Button/Button";

import rectangleImg from "../../images/circleImg1.png";

const AboutMenu = () => {
  const ageDifference = new Date() - new Date("2000-07-16");
  const ageDate = new Date(ageDifference);
  const age = Math.abs(ageDate.getUTCFullYear() - 1970);

  return (
    <>
      <div className={styles.about}>
        <div className={styles.heading}>
          <h2>
            ABOUT <span>ME</span>
          </h2>
        </div>
        <div className={styles.content}>
          <div className={styles.imageContainer}>
            <div className={styles.imgContainer}>
              <img src={rectangleImg} alt="" />
            </div>
          </div>
          <div className={styles.textContainer}>
            <div className={styles.text}>
              <p>
                As a Full Stack Software Engineer with expertise in HTML, CSS, JavaScript, React, Node.js, Python, PHP, and SQL, I build intuitive user interfaces and robust back-end systems. My experience also extends to data science, allowing me to leverage data-driven insights in my development process. I’m committed to writing clean, efficient code and continuously exploring the latest technologies to enhance functionality and user experience. I thrive in collaborative environments, delivering high-quality software solutions that meet both technical and user needs.
              </p>
            </div>
            <div className={styles.allBasicInformation}>
              <div className={styles.basicInformationLeft}>
                <div className={styles.basicInformation}>
                  <h4>Name:</h4>
                  <p>Faith Kebenei</p>
                </div>
                <div className={styles.basicInformation}>
                  <h4>Phone:</h4>
                  <p>0723850918</p>
                </div>
                <div className={styles.basicInformation}>
                  <h4>Experience:</h4>
                  <p>{new Date().getFullYear() - 2021} Years</p>
                </div>
              </div>
              <div className={styles.basicInformationRight}>
                <div className={styles.basicInformation}>
                  <h4>Age:</h4>
                  <p>{age}</p>
                </div>
                <div className={styles.basicInformation}>
                  <h4>Email:</h4>
                  <p>faithkebenei16@gmail.com</p>
                </div>
                <div className={styles.basicInformation}>
                  <h4>Freelance: </h4>
                  <p>Available</p>
                </div>
              </div>
            </div>
            <div className={styles.button}>
              <Button
                backgroundColor="#ea5184"
                color="#fff"
                text="View Resume"
                border="none"
                clicked={()=>window.open("https://drive.google.com/file/d/14BG6IRaE_PdDbvXhEzY4PplwNGtrzQE9/view?usp=sharing")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutMenu;
