import React from "react";

import styles from "./HomeMenu.module.scss";

import Socials from "../Socials/Socials";

import { FaTwitter, FaWhatsapp, FaGithub, FaLinkedinIn, FaChevronDown } from "react-icons/fa";
import { FaPhoneVolume, FaXTwitter } from "react-icons/fa6";

const HomeMenu = () => {
  const handleArrowClick = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className={styles.home}>
        <div className={styles.textContainer}>
          <p>Hello, I'm</p>
          <h3>Faith Kebenei</h3>
          <hr className={styles.nameHr} />
          <p>Software Developer</p>
          <div className={styles.socials}>
            <Socials icon={<FaGithub color="#fff" />} link="https://github.com/Faithkebenei" />
            <Socials icon={<FaLinkedinIn color="#fff" />} link="https://linkedin.com/in/faith-kebenei-801949205" />
            <Socials icon={<FaXTwitter color="#fff" />} link="https://x.com/kebenei_faith" />
            <Socials icon={<FaPhoneVolume color="#fff"/>} link="tel:+254723850918" num="phone" />
            <Socials icon={<FaWhatsapp color="#fff" />} link="https://wa.me/254723850918" />
          </div>
        </div>
        <div className={styles.arrowDown} onClick={handleArrowClick}>
          <FaChevronDown size="2rem" color="#ea5184" />
        </div>
      </div>
    </>
  );
};

export default HomeMenu;
