import React from "react";

import styles from "./Button.module.scss";

const Button = ({ backgroundColor, color, text, border, clicked, icon, width, size }) => {
  const buttonStyles = {
    backgroundColor: backgroundColor,
    color: color,
    border: border,
    width: width,
    padding: size === "sm" && "6px 12px",
    letterSpacing: size === "sm" && "0" 
  };
  return (
    <>
      <button style={buttonStyles} className={styles.button} onClick={clicked}>
        {icon} {text}
      </button>
    </>
  );
};

export default Button;
