import React from "react";
import styles from "./Projects.module.scss";
import ProjectCard from "../ProjectCard/ProjectCard";
import africana from "../../images/africana.png"
import promptopia from "../../images/promptopia.png"
import traveltrek from "../../images/traveltrek.png"
import seedlings from "../../images/seedlings.png"
import gymexercises from "../../images/gymexercises.png"
import movieland from "../../images/movieland.png"


const Projects = () => {
  const projects = [
  { id: 1, name: "Africana Hotel", image: africana, stack: ["React JS", "SCSS, Node JS, Mongo DB"], link: "https://africana-lemon.vercel.app/", code:"https://github.com/Faithkebenei/africana.git"},
  { id: 2, name: "Gym Exercises", image: gymexercises, stack: ["React JS", "Tailwind CSS"], link: "https://gym-exercises-weld.vercel.app/", code:"https://github.com/Faithkebenei/gym_exercises.git" },
  { id: 3, name: "Travel Trek", image: traveltrek, stack: ["React JS", "CSS"], link:"https://traveltrek-rosy.vercel.app/", code:"https://github.com/Faithkebenei/traveltrek.git" },
  { id: 4, name: "MovieLand", image: movieland, stack: ["React JS", "CSS"], link: "https://movie-land-lilac.vercel.app/", code:"https://github.com/Faithkebenei/MovieLand.git" },
  { id: 5, name: "Seedlings", image: seedlings, stack: ["React JS", "SCSS"], link:"https://seedlings-mu.vercel.app/", code:"https://github.com/Faithkebenei/seedlings.git" },
  { id: 6, name: "Promptopia", image: promptopia, stack: ["Next JS", "Tailwind CSS, Mongo DB, Google Auth"], link:"https://promptopia-project-sepia.vercel.app/", code:"https://github.com/Faithkebenei/promptopia.git" },]

  return (
    <div className={styles.projects}>
      <div className={styles.heading}>
        <h4>
          MY <span>PROJECTS</span>
        </h4>
      </div>
      <div className={styles.cards}>
        <div className={styles.projectCard}>
          <div className={styles.imageContainer}></div>
        </div>
        <div className={styles.projectsContainer}>
          {projects.map((project, index) => (
            <ProjectCard
              image={project.image}
              heading={project.name}
              stack={project.stack}
              link={project.link}
              code={project.code}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
