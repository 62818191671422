import React, { useState } from "react";
import styles from "./ContactMenu.module.scss";
import Button from "../Button/Button";
import emailjs from 'emailjs-com';

const ContactMenu = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendMessage = (e) => {
    e.preventDefault();

    const YOUR_SERVICE_ID = "service_h4r7fer";
    const YOUR_TEMPLATE_ID = "template_lq1lgpf";
    const YOUR_USER_ID = "nwyXwFLn0xyJnuYXf";
    // const YOUR_USER_ID = "yzb74fRTf0DBS5_ISCGHY";
    emailjs.sendForm(`${YOUR_SERVICE_ID}`, `${YOUR_TEMPLATE_ID}`, e.target, `${YOUR_USER_ID}`)
      .then((result) => {
        console.log(result.text);
        alert('Message sent successfully!');
      }, (error) => {
        console.log(error.text);
        alert('Failed to send the message.');
      });
  };

  return (
    <div className={styles.contact}>
      <div className={styles.heading}>
        <h4>
          GET IN <span>TOUCH</span>
        </h4>
      </div>
      <form onSubmit={sendMessage}>
        <div className={styles.container}>
          <div className={styles.box}>
            <label htmlFor="name" className={styles.label}>
              Name:
            </label>
            <input 
              id="name" 
              type="text" 
              name="user_name" 
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div className={styles.box}>
            <label htmlFor="email" className={styles.label}>
              Email:
            </label>
            <input 
              id="email" 
              type="email" 
              name="user_email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required 
            />
          </div>

          <div className={styles.box}>
            <label htmlFor="message" className={styles.label}>
              Message:
            </label>
            <textarea 
              id="message" 
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              cols="55" 
              rows="10" 
              required
            ></textarea>
          </div>
        </div>
        <div className={styles.button}>
          <Button
            backgroundColor="#ea5184"
            color="#fff"
            text="Send"
            border="none"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default ContactMenu;
