import React from "react";

import styles from "./Footer.module.scss";

import Socials from "../Socials/Socials";

import { FaTwitter, FaWhatsapp, FaGithub, FaLinkedinIn } from "react-icons/fa";
import { FaPhoneVolume, FaXTwitter } from "react-icons/fa6";

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <>
            <div className={styles.footer}>
                <div className={styles.footerContent}>
                    <div className={styles.socials}>
                        <Socials icon={<FaGithub color="#fff" />} link="https://github.com/Faithkebenei" />
                        <Socials icon={<FaLinkedinIn color="#fff" />} link="https://linkedin.com/in/faith-kebenei-801949205" />
                        <Socials icon={<FaXTwitter color="#fff" />} link="https://x.com/kebenei_faith" />
                        <Socials icon={<FaPhoneVolume color="#fff"/>} link="tel:+254723850918" num="phone" />
                        <Socials icon={<FaWhatsapp color="#fff" />} link="https://wa.me/254723850918" />
                    </div>
                    <p>All rights reserved © {year} <span>Faith Kebenei</span></p>
                </div>
            </div>
        </>
    )
}

export default Footer;