import React, { useState } from "react";
import styles from "./ProjectCard.module.scss";
import Button from "../Button/Button";
import { FaCode, FaGlobe } from "react-icons/fa";

const ProjectCard = ({ image, heading, text, stack, link, code }) => {

  return (
    <div className={styles.ProjectCard}>
      <div className={styles.imageContainer}>
        <img src={image} alt="" />
      </div>
      <div className={styles.text}>
        <h2>{heading}</h2>
        <p>{text}</p>
        <p className={styles.stack}>{stack.join(', ')}</p>
      </div>
      <div className={styles.buttons}>
        <Button
          backgroundColor="#41bcc5"
          color="#fff"
          text="Live Site"
          border="none"
          width="auto"
          size="sm"
          clicked={()=>window.open(link)}
          icon={<FaGlobe style={{marginRight: "5px"}}/>}
        />

        <Button
          backgroundColor="#41bcc5"
          color="#fff"
          text="Code"
          border="none"
          width="auto"
          size="sm"
          clicked={()=>window.open(code)}
          icon={<FaCode style={{marginRight: "5px"}}/>}
        />
      </div>
    </div>
  );
};

export default ProjectCard;
